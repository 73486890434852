import "./App.css";
import fullLogo from "./images/logo_bandeau.png";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link,
	Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Tutorial from "./pages/Tutorial";
import Contact from "./pages/Contact";
import { slide as Menu } from "react-burger-menu";
import React, { useState } from "react";

function App() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	// Fonction pour ouvrir/fermer le menu
	const handleStateChange = (state) => setIsMenuOpen(state.isOpen);

	// Fonction pour fermer le menu lors de la sélection d'un lien
	const closeMenu = () => setIsMenuOpen(false);

	return (
		<Router>
			<div className="container">
				<header>
					<div style={{ width: "40%" }}>
						<Link to="/Home" className="logo">
							<img src={fullLogo} alt="Gymnote Logo" className="logo-image" />
						</Link>
					</div>
					{/* Menu de navigation en version PC */}
					<div style={{ width: "25%" }} className="menu desktop-menu">
						<Link to="/Home" className="menu-item">
							Concept
						</Link>
						<div
							style={{ height: "80%", borderRight: "1px solid black" }}
						></div>
						<Link to="/Tutorial" className="menu-item">
							Tuto
						</Link>
						<div
							style={{ height: "80%", borderRight: "1px solid black" }}
						></div>
						<Link to="/contact" className="menu-item">
							Contact
						</Link>
					</div>

					{window.innerWidth < 769 && (
						<Menu
							right
							isOpen={isMenuOpen}
							onStateChange={handleStateChange}
							className="mobile-menu"
						>
							<Link to="/Home" className="menu-item" onClick={closeMenu}>
								Concept
							</Link>
							<Link to="/Tutorial" className="menu-item" onClick={closeMenu}>
								Tuto
							</Link>
							<Link to="/contact" className="menu-item" onClick={closeMenu}>
								Contact
							</Link>
						</Menu>
					)}
				</header>
				<main>
					<Routes>
						<Route path="/" element={<Navigate to="/Home" />} />
						<Route path="/Home" element={<Home />} />
						<Route path="/Home" element={<Navigate to="/Home" />} />
						<Route path="/Tutorial" element={<Tutorial />} />
						<Route path="/contact" element={<Contact />} />
					</Routes>
				</main>
			</div>
		</Router>
	);
}

export default App;
