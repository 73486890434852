import React from "react";
import "../App.css";

function Tutorial() {
	return (
		<div className="page">
			<div className="page-content">
				<h1>Tutorial</h1>
			</div>
		</div>
	);
}

export default Tutorial;
