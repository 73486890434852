import React from "react";
import "../App.css";
import image1 from "../images/image856.png";
import image2 from "../images/image867.png";
import image3 from "../images/image878.png";
import image4 from "../images/image966.png";
import LogoText from "../utils";

function Home() {
	return (
		<div className="page">
			<div className="page-content">
				<div className="page-content-row">
					<img
						src={image1}
						alt="Gymnote screenshot"
						className="screen-image hide-on-mobile"
					/>
					<div>
						<h1>
							Bienvenue sur <LogoText />
						</h1>
						<p>
							Gymnote est une application simple et intuitive à utiliser à la
							salle de sport. Elle va te permettre de noter tes performances sur
							chaque série de chaque exercice, séance après séance. Ainsi, il te
							suffira de faire mieux aujourd'hui que la fois précédente pour
							enregistrer une progression.
						</p>
						<br />
						<h2>
							L'importance de noter ses{" "}
							<span className="logo-color">performances</span>
						</h2>
						<p>
							Le principe fondamental de la progression en musculation est la
							surcharge progressive. C’est-à-dire augmenter progressivement les
							contraintes de charge ou de difficulté sur chaque groupe
							musculaire, au travers des exercices réalisés.
						</p>
					</div>
					<img src={image4} alt="Gymnote screenshot" className="screen-image" />
				</div>
				<div style={{ marginBottom: 20 }} className="page-content-row">
					<div>
						<h2>
							La <span className="logo-color">surcharge</span> progressive peut
							se matérialiser de différentes manières
						</h2>
					</div>
				</div>
				<div className="page-content-row">
					<div>
						<strong>
							- Augmentation de la charge (Très facile à mesurer, applicable sur
							le long terme)
						</strong>
						<p>
							Très facile à mesurer et à enregistrer avec une assez bonne
							précision, sur cette métrique, on peut partir de très bas en début
							de progression, et finir très haut après plusieurs années, c’est
							donc également applicable sur le long terme.
						</p>
						<br />
						<strong>
							- Augmentation du nombre de répétitions (Très facile à mesurer,
							applicable sur le moyen terme)
						</strong>
						<p>
							Très facile à mesurer et à enregistrer également, il suffit de
							compter les répétitions sur chaque série. La marge de progression
							sur cette métrique est un peu plus réduite car dans une optique de
							force ou d’hypertrophie, ou aura tendance à augmenter la charge
							dès que le nombre de répétition dépasse un certain seuil.
						</p>
					</div>
					<div>
						<strong>
							- Diminution du temps de récupération (Facile à mesurer,
							applicable sur le moyen terme)
						</strong>
						<p>
							Plutôt facile à mesurer à l’aide d’un chronomètre, même s’il peut
							exister un léger flou vis-à-vis des petits temps morts au début et
							à la fin de la série. Peut être une stratégie de progression à
							court ou moyen terme, mais ne se suffit pas à lui-même car on ne
							peut pas réduire indéfiniment le temps de repos.
						</p>
						<br />
						<strong>
							- Augmentation de l’amplitude et des tempos (Difficile à mesurer,
							applicable sur le court terme)
						</strong>
						<p>
							Difficile d’évaluer précisément l’amplitude, ou de mesurer des
							tempos d’exécution. De plus, les marges de progression sur ces
							métriques-là sont assez réduites. Cela peut faire partie d’une
							stratégie de progression à court terme mais il faudra l’utiliser
							en complément d’autres indicateurs de progression.
						</p>
					</div>
				</div>
				<div className="page-content-row">
					<img src={image2} alt="Gymnote screenshot" className="screen-image" />
					<div>
						<h2>
							Quels <span className="logo-color">choix</span> pour <LogoText />?
						</h2>
						<p>
							Notre approche scientifique et systémique du sport nous pousse
							donc à privilégier les deux premières stratégies de surcharges
							progressives car elles nous permettent d’avoir un contrôle total,
							précis et long terme sur notre progression. Une célèbre citation
							résume très bien cet état d’esprit : « You can’t manage what you
							can’t measure » En français : « Tu ne peux pas contrôler ce que tu
							ne mesures pas ». Au travers de ces explications, nous espérons
							t’avoir convaincu de l’importance de noter tes performances pour
							savoir où tu en es. En bonus, tu auras la satisfaction de
							constater le chemin parcouru et tu seras probablement surpris de
							tes progrès.
						</p>
					</div>
					<img src={image3} alt="Gymnote screenshot" className="screen-image" />
				</div>
			</div>
		</div>
	);
}

export default Home;
