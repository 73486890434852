import React, { useEffect, useRef } from "react";
import "../App.css";

function Contact() {
	const emailRef = useRef(null);

	useEffect(() => {
		if (emailRef.current) {
			const email = emailRef.current.getAttribute("data-email");
			emailRef.current.innerHTML = `<a href="mailto:${email}">Envoyer un message à GymNote</a>`;
		}
	}, []);

	return (
		<div className="page">
			<div className="page-content">
				<div style={{ textAlign: "center" }}>
					<h1>Contact</h1>
					<p>
						Si vous avez des suggestions ou des bugs à nous signaler,
						contactez-nous.
					</p>
					<h3>
						<span ref={emailRef} data-email="contact@gymnote.fr">
							Envoyer un message à GymNote
						</span>
					</h3>
				</div>
			</div>
		</div>
	);
}

export default Contact;
