import React from "react";
import "./App.css";

function LogoText() {
	return (
		<span>
			Gym<span className="logo-color">Note</span>
		</span>
	);
}

export default LogoText;
